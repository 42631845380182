<template>
  <!-- 个人中心 - 收藏夹 -->
  <div class="collect">
    <div class="title">
      <p>{{ $route.meta.name }}</p>
      <div class="add-folder" @click="$refs['childMain'].addFolder()" v-if="$route.path == '/personal'">
        <span class="icon-Add iconfont"></span>新建文件夹
      </div>
    </div>
    <router-view ref="childMain"></router-view>
  </div>
</template>
<script>
export default {
  name: "Directory",
  data () {
    return {
    };
  },

  created () {
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.collect {
  padding-top: 28px;

  .title {
    padding: 0 66px 0 41px;
    @include flex(center, space-between);
    height: 42px;
    margin-bottom: 29px;

    p {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      line-height: 28px;
    }

    div {
      width: 120px;
      height: 42px;
      @include flex(center, center);
      border-radius: 4px;
      border: 1px solid #222222;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1f1f1f;
      cursor: pointer;

      span {
        margin-right: 8px;
        font-size: 12px;
      }
    }
  }
}
</style>
